<template>
  <section class="bg-light">
    <div class="container py-8 ">
      <div class="text-primary font-display font-bold text-5xl text-center mb-4">Acerca de Friso</div>
      <div class="text-primary mb-4">Friso es una marca global producida y empacada en Holanda por FrieslandCampina, una de las empresas líderes en productos lácteos. La leche que contienen nuestros productos Friso proviene de nuestras propias granjas. Ponemos un cuidado especial en nuestras granjas, desde la calidad del suelo hasta el alimento de las vacas. Nuestros granjeros tienen una orgullosa herencia ya que provienen de familias de granjeros con más de 140 años de experiencia.</div>
      
      <div class="w-64 mx-auto mb-8">
        <img src="/img/about/logo.png" />
      </div>

      <div class="w-96 mx-auto mb-8">
        <img src="/img/about/lifecycle.png" />
      </div>

      <div class="text-tertiary font-display font-bold text-4xl text-center mb-4">¡Esto es lo que hace a Friso único!</div>
      <div class="text-primary font-bold mb-4">Control sobre toda la cadena de producción</div>
      <div class="text-primary mb-8">Friso utiliza sólo ingredientes de alta calidad y las técnicas de procesamiento más innovativas para crear fórmulas de excelencia. El procesamiento mínimo y los bajos niveles de proteínas modificadas por calor son esenciales para obtener proteínas de la leche de fácil digestión. Al tener el control sobre toda la cadena de producción, desde la alimentación de las vacas, hasta el producto final en cada lata de Friso, garantizamos la calidad de nuestros productos a los profesionales de la salud y a los padres alrededor del mundo.</div>

      <div class="text-primary font-display text-center font-bold text-4xl -mb-4">Del pasto al vaso</div>
      <div class="w-96 mx-auto mb-8">
        <img src="/img/about/from-grass-to-glass.png" />
      </div>

    </div>
  </section>
</template>
